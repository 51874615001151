var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "content" } }, [
    _c("div", { staticClass: "container" }, [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center mt-5"
            },
            [
              _c("span", {
                staticClass: "spinner-grow spinner-grow-sm mx-3",
                staticStyle: { width: "2rem", height: "2rem" },
                attrs: { role: "status", "aria-hidden": "true" }
              }),
              _c("span", { staticClass: "text-md" }, [_vm._v("Loading....")])
            ]
          )
        : _c("div", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-xl-8" }, [
                _c("h1", [_vm._v("Need help finding your replacement model?")]),
                _c("p", [
                  _vm._v(
                    "Enter the model or name of your existing appliance below to find your replacement model."
                  )
                ]),
                _c(
                  "form",
                  {
                    staticClass: "form",
                    attrs: { novalidate: "" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.getProducts($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "input-group mb-4" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.query,
                            expression: "query"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          placeholder: "Search product name or code"
                        },
                        domProps: { value: _vm.query },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.query = $event.target.value
                            },
                            function($event) {
                              _vm.query = _vm.query.replace(/[\/\\]/g, "")
                            }
                          ]
                        }
                      }),
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-alt btn-primary",
                            attrs: {
                              type: "submit",
                              id: "input-search-button",
                              disabled: _vm.query.length == 0
                            }
                          },
                          [
                            _vm._v(" Search "),
                            _c("i", {
                              staticClass: "fa fa-angle-right pl-2",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ])
                    ]),
                    _vm.showNotFoundMessage
                      ? _c(
                          "div",
                          {
                            staticClass: "alert alert-danger",
                            attrs: { role: "alert" }
                          },
                          [
                            _vm._v(
                              " Unfortunately, we couldn’t find your model number. Our expert product advisors will be happy to assist you in finding the right product, please contact us on "
                            ),
                            _c("a", { attrs: { href: "tel:03448921097" } }, [
                              _vm._v(" 03448921097")
                            ])
                          ]
                        )
                      : _vm._e(),
                    _c("p", [
                      _vm._v("Note: Enter the model number not the ENR number.")
                    ]),
                    _c("p", [
                      _vm._v(
                        "Example KUR15A50GB/02 will not display any results, KUR15A50GB will show the replacement options."
                      )
                    ])
                  ]
                )
              ])
            ]),
            _vm.searching
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-center pt-5" },
                  [
                    _c("span", {
                      staticClass: "spinner-grow spinner-grow-sm",
                      staticStyle: { width: "4rem", height: "4rem" },
                      attrs: { role: "status", "aria-hidden": "true" }
                    })
                  ]
                )
              : _vm.product && _vm.product.id
              ? _c("div", { staticClass: "search-result" }, [
                  _c("h2", [
                    _vm._v(
                      'Showing results for "' +
                        _vm._s(_vm.requestedQuery) +
                        '".'
                    )
                  ]),
                  _c("div", { staticClass: "inner-wrapper" }, [
                    _c("div", { staticClass: "result-column mb-5 mb-lg-0" }, [
                      _c("h3", [_vm._v("Your searched model")]),
                      _c("div", { staticClass: "product-description" }, [
                        _c("div", { staticClass: "product-wrapper" }, [
                          _c("div", { staticClass: "product" }, [
                            _c(
                              "div",
                              { staticClass: "slider product-slider" },
                              [
                                _c("figure", [
                                  _c("img", {
                                    staticStyle: {
                                      width: "auto",
                                      height: "auto"
                                    },
                                    attrs: {
                                      src:
                                        "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                        _vm.product.featured_image +
                                        ".jpg",
                                      alt: _vm.product.code
                                    }
                                  })
                                ])
                              ]
                            ),
                            _c("div", { staticClass: "details" }, [
                              _c("h3", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.product.brand.name) +
                                    " " +
                                    _vm._s(_vm.product.series) +
                                    " " +
                                    _vm._s(_vm.product.code) +
                                    " " +
                                    _vm._s(_vm.product.name) +
                                    " "
                                )
                              ]),
                              _c("div", { staticClass: "meta" }, [
                                _c("span", { staticClass: "product-code" }, [
                                  _vm._v(
                                    "Product code: " + _vm._s(_vm.product.code)
                                  )
                                ])
                              ]),
                              _vm.product.is_featured
                                ? _c("span", { staticClass: "featured-tag" }, [
                                    _vm._v("Buy Direct Model")
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "tabs2" }, [
                                _vm._m(0),
                                _c("div", { staticClass: "tab-content" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane fade show active",
                                      attrs: { id: "tab3", role: "tabpanel" }
                                    },
                                    [
                                      _c("perfect-scrollbar", [
                                        _vm.product.specifications ==
                                        "<div>\n  <ul></ul>\n</div>"
                                          ? _c("div", [
                                              _vm._v(
                                                " Product Specification currently not available"
                                              )
                                            ])
                                          : _c("p", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.product.specifications
                                                )
                                              }
                                            })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tab-pane fade",
                                      attrs: { id: "tab4", role: "tabpanel" }
                                    },
                                    [
                                      _c("perfect-scrollbar", [
                                        _vm.product.description == "<div></div>"
                                          ? _c("div", [
                                              _vm._v(
                                                "Product Information currently not available"
                                              )
                                            ])
                                          : _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.product.description
                                                )
                                              }
                                            })
                                      ])
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              !_vm.successorProduct.id
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-alt btn-primary",
                                      attrs: {
                                        href:
                                          "https://bosch-home.co.uk/store/" +
                                          _vm.product.code,
                                        id: "input-search-button"
                                      }
                                    },
                                    [
                                      _vm._v(" Click here to buy online "),
                                      _c("i", {
                                        staticClass: "fa fa-angle-right pl-2",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "result-column bg-1" }, [
                      _c("h3", [_vm._v("Replacement model")]),
                      _vm.successorProduct && _vm.successorProduct.name != ""
                        ? _c("div", { staticClass: "product-description" }, [
                            _c("div", { staticClass: "product-wrapper" }, [
                              _c("div", { staticClass: "product" }, [
                                _c(
                                  "div",
                                  { staticClass: "slider product-slider" },
                                  [
                                    _c("figure", [
                                      _c("img", {
                                        staticStyle: {
                                          width: "auto",
                                          height: "auto"
                                        },
                                        attrs: {
                                          src:
                                            "https://media3.bosch-home.com/Product_Shots/600x337/" +
                                            _vm.successorProduct
                                              .featured_image +
                                            ".jpg",
                                          alt: _vm.successorProduct.code
                                        }
                                      })
                                    ])
                                  ]
                                ),
                                _c("div", { staticClass: "details" }, [
                                  _c("h3", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.successorProduct.brand.name
                                        ) +
                                        " " +
                                        _vm._s(_vm.successorProduct.series) +
                                        " " +
                                        _vm._s(_vm.successorProduct.code) +
                                        " " +
                                        _vm._s(_vm.successorProduct.name) +
                                        " "
                                    )
                                  ]),
                                  _c("div", { staticClass: "meta" }, [
                                    _c(
                                      "span",
                                      { staticClass: "product-code" },
                                      [
                                        _vm._v(
                                          "Product code: " +
                                            _vm._s(_vm.successorProduct.code)
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm.successorProduct.is_featured
                                    ? _c(
                                        "span",
                                        { staticClass: "featured-tag" },
                                        [_vm._v("Buy Direct Model")]
                                      )
                                    : _vm._e(),
                                  _c("div", { staticClass: "tabs2" }, [
                                    _vm._m(1),
                                    _c("div", { staticClass: "tab-content" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tab-pane fade show active",
                                          attrs: {
                                            id: "tab1",
                                            role: "tabpanel"
                                          }
                                        },
                                        [
                                          _c("perfect-scrollbar", [
                                            _vm.successorProduct
                                              .specifications ==
                                            "<div>\n  <ul></ul>\n</div>"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " Product Specification currently not available"
                                                  )
                                                ])
                                              : _c("p", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.successorProduct
                                                        .specifications
                                                    )
                                                  }
                                                })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "tab-pane fade",
                                          attrs: {
                                            id: "tab2",
                                            role: "tabpanel"
                                          }
                                        },
                                        [
                                          _c("perfect-scrollbar", [
                                            _vm.successorProduct.description ==
                                            "<div></div>"
                                              ? _c("div", [
                                                  _vm._v(
                                                    " Product Information currently not available"
                                                  )
                                                ])
                                              : _c("div", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.successorProduct
                                                        .description
                                                    )
                                                  }
                                                })
                                          ])
                                        ],
                                        1
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn btn-alt btn-primary",
                                      attrs: {
                                        href:
                                          "https://bosch-home.co.uk/store/" +
                                          _vm.successorProduct.code,
                                        id: "input-search-button"
                                      }
                                    },
                                    [
                                      _vm._v(" Click here to buy online "),
                                      _c("i", {
                                        staticClass: "fa fa-angle-right pl-2",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ])
                              ])
                            ])
                          ])
                        : _c("div", { staticClass: "pt-4" }, [_vm._m(2)])
                    ])
                  ])
                ])
              : _vm._e()
          ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
      [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "active",
              attrs: { "data-toggle": "tab", href: "#tab3", role: "tab" }
            },
            [_vm._v(" Specification")]
          )
        ]),
        _c("li", [
          _c(
            "a",
            { attrs: { "data-toggle": "tab", href: "#tab4", role: "tab" } },
            [_vm._v("Product Info")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { role: "tablist" } },
      [
        _c("li", [
          _c(
            "a",
            {
              staticClass: "active",
              attrs: { "data-toggle": "tab", href: "#tab1", role: "tab" }
            },
            [_vm._v(" Specification")]
          )
        ]),
        _c("li", [
          _c(
            "a",
            { attrs: { "data-toggle": "tab", href: "#tab2", role: "tab" } },
            [_vm._v("Product Info")]
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-1" }, [
      _c("h6", [
        _vm._v("No direct replacement or similar products available.")
      ]),
      _c("p", [
        _vm._v(
          " Our expert product advisors will be happy to assist you in finding the right product, please contact us on "
        ),
        _c(
          "a",
          { staticClass: "text-underline", attrs: { href: "tel:03448921097" } },
          [_vm._v("03448921097")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }