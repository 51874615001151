// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/BoschSans-Regular.eot");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/fonts/BoschSans-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/fonts/BoschSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/fonts/BoschSans-Bold.eot");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../assets/fonts/BoschSans-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../assets/fonts/BoschSans-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "\n@font-face {\n    font-family: \"Bosch Sans\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n@font-face {\n    font-family: \"Bosch Sans\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('embedded-opentype'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('truetype');\n    font-weight: 700;\n    font-style: normal;\n}\nbody {\n    background-color: #fff;\n    font-family: 'Bosch Sans';\n    font-size: 14px;\n}\n", ""]);
// Exports
module.exports = exports;
